import React, { useState, useRef, useEffect } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
import Autocomplete from "@mui/material/Autocomplete";
import { DateCalendar } from "@mui/x-date-pickers";
import PencilIcon from "@heroicons/react/24/solid/PencilIcon";
import PlusCircleIcon from "@heroicons/react/24/solid/PlusCircleIcon";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { RightDrawerLayout } from "../layouts/RightDrawerLayout";
import { Form } from "../common/Form";
import I18n from "../common/I18n";
import i18n from "../../utils/i18n";
import routes from "../../utils/routes";
import dayjs from "dayjs";
import RefreshButton from "../common/RefreshButton";
import Dropdown from "../common/Dropdown";
import SubmitButton from "../common/SubmitButton";
import Confirm from "../common/Confirm";
import NumberInput from "../common/NumberInput";
import api from "../../utils/api";
import currencyName from "../../utils/currency-name";

const SCHEMA = yup.object({
  name: yup
    .string()
    .required(i18n.t("field_cannot_be_blank", { field: i18n.t("name") })),
  currency: yup
    .string()
    .required(i18n.t("field_cannot_be_blank", { field: i18n.t("currency") })),
  amount: yup
    .number()
    .transform((_, value) =>
      typeof value == "string" ? parseFloat(value.replace(/,/g, "")) : value,
    )
    .positive(i18n.t("this_field_must_be_positive"))
    .required(i18n.t("field_cannot_be_blank", { field: i18n.t("amount") })),
  recipient_id: yup.string().when("item_type", {
    is: "expense_payee",
    then: (schema) =>
      schema.required(
        i18n.t("field_cannot_be_blank", { field: i18n.t("recipient") }),
      ),
    otherwise: (schema) => schema.optional(),
  }),
  reference: yup.string().when("item_type", {
    is: "expense_payee",
    then: (schema) =>
      schema.required(
        i18n.t("field_cannot_be_blank", { field: i18n.t("payment_reference") }),
      ),
    otherwise: (schema) => schema.optional(),
  }),
  reason: yup.string().when("item_type", {
    is: "expense_payee",
    then: (schema) =>
      schema.required(
        i18n.t("field_cannot_be_blank", { field: i18n.t("payment_reason") }),
      ),
    otherwise: (schema) => schema.optional(),
  }),
});

const REPETITIONS = [
  { value: "none", name: i18n.t("no_repetition") },
  { value: "every_month", name: i18n.t("same_day_every_month") },
  { value: "every_week", name: i18n.t("same_day_every_week") },
];

function FormDrawerContent({
  defaultCurrency,
  defaultDay,
  item,
  bank_accounts,
  onClose,
  switchToTransactionForm,
  current_user,
  account_admin,
  readOnly,
}) {
  const disabled =
    ["completed"].includes(item?.status) ||
    current_user.role_value == "read_only" ||
    readOnly;
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmUpdate, setConfirmUpdate] = useState(false);
  const repeatingInputRef = useRef();
  const [recipients, setRecipients] = useState([]);
  const [retrievingRecipients, setRetrievingRecipients] = useState(false);
  const [paymentReasons, setPaymentReasons] = useState(
    item?.data?.reason
      ? [
          {
            value: item.data.reason,
            name: item.data.reason.split(":_:")[1],
          },
        ]
      : [],
  );
  const [retrievingPaymentReasons, setRetrievingPaymentReasons] =
    useState(false);
  const onClickDelete = () => setConfirmDelete(true);
  const onCloseDelete = () => setConfirmDelete(false);
  const onClickUpdate = () => setConfirmUpdate(true);
  const onCloseUpdate = () => setConfirmUpdate(false);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: item?.description || i18n.t("new_cash_flow_item"),
      bank_account_id: item?.bank_account?.id || bank_accounts[0]?.id,
      currency: item?.currency || defaultCurrency,
      amount: Math.abs(item?.amount || 1000),
      reason: item?.data?.reason,
      reference: item?.data?.reference,
      date: item?.date || defaultDay,
      frequency: item?.repetition?.frequency,
      item_type:
        item?.amount < 0
          ? "income"
          : item?.data?.recipient_id || item?.status == "pending"
          ? "expense_payee"
          : "expense",
      recipient_id: item?.data?.recipient_id,
    },
    resolver: yupResolver(SCHEMA),
  });
  const amount = watch("amount");
  const currency = watch("currency");
  const bank_account =
    item?.bank_account ||
    bank_accounts.find(
      (_bank_account) => _bank_account.id == watch("bank_account_id"),
    );
  const bankAccountValues = (
    !item ||
    bank_accounts.find(
      (_bank_account) => item.bank_account.id == _bank_account.id,
    )
      ? []
      : [item.bank_account]
  )
    .concat(bank_accounts)
    .map((_bank_account) => ({
      value: _bank_account.id,
      name: _bank_account.name,
    }));
  const defaultBankAccount = bank_accounts.filter(
    (_bank_account) => _bank_account.currency == defaultCurrency,
  )[0];
  const item_type = watch("item_type");
  useEffect(() => {
    setRecipients(bank_account?.recipients || []);
    if (!item && item_type == "expense_payee") {
      setValue("item_type", "expense");
      setValue("recipient_id", undefined);
      setValue("reason", undefined);
    }
  }, [bank_account]);
  useEffect(() => {
    if (item_type != "expense_payee") setValue("recipient_id", undefined);
    if (item_type == "expense_payee" && !item) retrievePaymentReasons();
  }, [item_type]);
  const refreshRecipients = () =>
    api({
      url: routes.retrieve_recipients_cashflows(),
      method: "get",
      data: { bank_account_id: bank_account?.id },
      onStart: () => setRetrievingRecipients(true),
      onError: () => setRetrievingRecipients(false),
      onSuccess: (recipients) => {
        setRecipients(recipients || []);
        setRetrievingRecipients(false);
      },
    });
  const retrievePaymentReasons = () =>
    api({
      url: routes.retrieve_payment_reasons_cashflows(),
      method: "get",
      data: {
        bank_account_id: bank_account.id,
        amount: getValues("amount"),
        currency: bank_account.currency,
        recipient_id: getValues("recipient_id"),
      },
      onStart: () => setRetrievingPaymentReasons(true),
      onError: () => setRetrievingPaymentReasons(false),
      onSuccess: (reasons) => {
        setRetrievingPaymentReasons(false);
        setPaymentReasons(reasons || []);
      },
    });
  const [audits, setAudits] = useState([]);
  const retrieveAudits = () =>
    api({
      url: routes.retrieve_audits_cashflow({ id: item?.id }),
      method: "get",
      onSuccess: (audits) => setAudits(audits),
    });
  useEffect(() => {
    if (item) {
      retrieveAudits();
      trigger(["reason", "recipient_id", "reference"]);
      if (item_type == "expense_payee" && !item?.data?.reason)
        retrievePaymentReasons();
    }
  }, []);

  return (
    <Stack spacing={2}>
      {bank_accounts.length == 0 && (
        <>
          <Card>
            <CardContent>
              <Typography variant="subtitle2">
                <I18n no_active_transactional_accounts />
                &nbsp;
                {account_admin && (
                  <I18n
                    click_here_account_integration
                    click_here={routes.account_integrations()}
                  />
                )}
              </Typography>
            </CardContent>
          </Card>
          <Button fullWidth variant="contained" onClick={onClose}>
            <I18n close />
          </Button>
        </>
      )}
      {bank_accounts.length > 0 && (
        <Form
          action={item ? routes.cashflow({ id: item.id }) : routes.cashflows()}
          method={item ? "put" : "post"}
          onSubmit={(event) =>
            handleSubmit(
              () => undefined,
              () => event.preventDefault(),
            )()
          }
        >
          <Card>
            <CardContent>
              <Stack spacing={2}>
                <DateCalendar
                  disabled={disabled}
                  minDate={dayjs().add(1, "day")}
                  defaultValue={item ? dayjs(item.date) : defaultDay}
                  onChange={(date) =>
                    setValue("date", date.format("YYYY-MM-DD"))
                  }
                />
                <input type="hidden" {...register("date")} />
                <TextField
                  fullWidth
                  required
                  disabled={disabled}
                  variant="standard"
                  label={i18n.t("reference_name")}
                  helperText={errors.name?.message}
                  {...register("name")}
                  error={"name" in errors}
                  autoFocus
                />
                <Dropdown
                  fullWidth
                  disabled={disabled}
                  variant="standard"
                  label={i18n.t("bank_account")}
                  values={bankAccountValues}
                  readOnly={!!item}
                  defaultValue={
                    item?.bank_account?.id || defaultBankAccount?.id
                  }
                  helperText={errors.bank_account_id?.message}
                  {...register("bank_account_id")}
                  error={"bank_account_id" in errors}
                />
                <FormControl>
                  <RadioGroup
                    row
                    name="item_type"
                    value={item_type}
                    onChange={(e) => setValue("item_type", e.target.value)}
                  >
                    <FormControlLabel
                      disabled={(!!item && item.amount < 0) || disabled}
                      componentsProps={{ typography: { variant: "body2" } }}
                      value="expense"
                      control={<Radio />}
                      label={i18n.t("expense_item")}
                    />
                    <FormControlLabel
                      disabled={
                        (!!item && item.amount < 0) ||
                        bank_account.bank_type == "pseudo_transactional" ||
                        bank_account.profile.capabilities.indexOf(
                          "create_payment_to_recipient",
                        ) == -1 ||
                        disabled
                      }
                      componentsProps={{ typography: { variant: "body2" } }}
                      value="expense_payee"
                      control={<Radio />}
                      label={i18n.t("expense_item_payee")}
                    />
                    <FormControlLabel
                      disabled={(!!item && item.amount > 0) || disabled}
                      componentsProps={{ typography: { variant: "body2" } }}
                      value="income"
                      control={<Radio />}
                      label={i18n.t("income_item")}
                    />
                  </RadioGroup>
                  <Typography variant="subtitle2" sx={{ mt: 0, ml: 3 }}>
                    *&nbsp;
                    {item_type == "expense" && (
                      <>
                        <I18n expense_subtitle />
                        {currency != bank_account.currency && (
                          <>
                            <br />
                            <I18n expense_with_fx_subtitle />
                            &nbsp;
                            {switchToTransactionForm && (
                              <I18n
                                click_here_schedule_fx_conversion
                                click_here={switchToTransactionForm}
                              />
                            )}
                          </>
                        )}
                      </>
                    )}
                    {item_type == "expense_payee" && (
                      <I18n expense_payee_subtitle />
                    )}
                    {item_type == "income" && <I18n income_subtitle />}
                  </Typography>
                </FormControl>
                {item_type == "expense_payee" && (
                  <div style={{ display: "flex" }}>
                    <Autocomplete
                      fullWidth
                      disabled={disabled}
                      variant="standard"
                      disableClearable
                      getOptionLabel={(option) =>
                        option.nickname
                          ? `${option.name} (${option.nickname})`
                          : option.name
                      }
                      groupBy={(option) => option.firstletter}
                      options={recipients.sort((a, b) =>
                        a.name.localeCompare(b.name),
                      )}
                      defaultValue={
                        item?.data?.recipient_id
                          ? {
                              recipient_id: item?.data?.recipient_id,
                              name: item?.data?.recipient_name,
                            }
                          : null
                      }
                      onChange={(_e, value) => {
                        setValue("recipient_id", value?.recipient_id);
                        if (value?.currency)
                          setValue("currency", value.currency);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label={i18n.t("recipient")}
                          helperText={errors.recipient_id?.message}
                          error={"recipient_id" in errors}
                          variant="standard"
                        />
                      )}
                      sx={{ mt: 0 }}
                    />
                    <RefreshButton
                      disabled={disabled}
                      title={i18n.t("refresh")}
                      sx={{ alignSelf: "end", ml: 1 }}
                      onClick={refreshRecipients}
                      loading={retrievingRecipients}
                    />
                    <input type="hidden" {...register("recipient_id")} />
                  </div>
                )}
                <Grid container>
                  <Grid item xs={3}>
                    <Autocomplete
                      fullWidth
                      disabled={disabled || !!item}
                      variant="standard"
                      disableClearable
                      getOptionLabel={(option) => option.name}
                      options={i18n.t("currencies")}
                      value={{
                        name: currencyName(currency),
                        value: currency,
                      }}
                      onChange={(_e, item) => setValue("currency", item.value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label={i18n.t("currency")}
                          helperText={errors.currency?.message}
                          error={"currency" in errors}
                          variant="standard"
                        />
                      )}
                    />
                    <input type="hidden" {...register("currency")} />
                  </Grid>
                  <Grid item xs={9} sx={{ pl: 2 }}>
                    <TextField
                      fullWidth
                      required
                      disabled={disabled}
                      variant="standard"
                      InputProps={{
                        inputComponent: NumberInput,
                        inputProps: {
                          value: amount,
                          ...register("amount"),
                        },
                      }}
                      label={i18n.t("amount")}
                      helperText={errors.amount?.message}
                      error={"amount" in errors}
                    />
                  </Grid>
                </Grid>
                {item_type == "expense_payee" && (
                  <div style={{ display: "flex" }}>
                    <Autocomplete
                      fullWidth
                      disabled={disabled}
                      variant="standard"
                      disableClearable
                      getOptionLabel={(option) => option.name}
                      options={paymentReasons}
                      defaultValue={paymentReasons.find(
                        (reason) => reason.value == item?.data?.reason,
                      )}
                      onChange={(_e, value) => setValue("reason", value?.value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label={i18n.t("payment_reason")}
                          helperText={errors.reason?.message}
                          error={"reason" in errors}
                          variant="standard"
                        />
                      )}
                      sx={{ mt: 0 }}
                    />
                    <RefreshButton
                      disabled={disabled}
                      title={i18n.t("refresh")}
                      sx={{ alignSelf: "end", ml: 1 }}
                      onClick={retrievePaymentReasons}
                      loading={retrievingPaymentReasons}
                    />
                    <input type="hidden" {...register("reason")} />
                  </div>
                )}
                {item_type == "expense_payee" && (
                  <TextField
                    fullWidth
                    required
                    disabled={disabled}
                    variant="standard"
                    label={i18n.t("payment_reference")}
                    helperText={errors.reference?.message}
                    {...register("reference")}
                    error={"reference" in errors}
                  />
                )}
                <Dropdown
                  fullWidth
                  disabled={disabled || !!item?.app_integration}
                  variant="standard"
                  label={i18n.t("repeat")}
                  helperText={errors.repeat?.message}
                  defaultValue={item?.repetition?.frequency || "none"}
                  values={REPETITIONS}
                  {...register("frequency")}
                  error={"frequency" in errors}
                />
              </Stack>
            </CardContent>
          </Card>
          <Box sx={{ mt: 2, display: "flex", flexDirection: "column" }}>
            {disabled && (
              <Button fullWidth variant="contained" onClick={onClose}>
                <I18n close />
              </Button>
            )}
            {!disabled &&
              ((!item && (
                <SubmitButton fullWidth variant="contained" type="submit">
                  <I18n create />
                </SubmitButton>
              )) ||
                (!item.repetition && (
                  <SubmitButton fullWidth variant="contained" type="submit">
                    <I18n update />
                  </SubmitButton>
                )) || (
                  <Button fullWidth variant="contained" onClick={onClickUpdate}>
                    <I18n update />
                  </Button>
                ))}
            {!disabled && item && (
              <Button
                onClick={onClickDelete}
                sx={{ mt: 1, alignSelf: "flex-end" }}
                color="error"
              >
                <I18n delete />
              </Button>
            )}
            <Confirm
              open={confirmDelete}
              onClose={onCloseDelete}
              title={i18n.t("delete_cash_flow_item")}
              actions={[
                {
                  onClick: onCloseDelete,
                  label: i18n.t("cancel"),
                },
                item?.repetition
                  ? {
                      href: routes.cashflow({
                        id: item?.id,
                        repeating: "all_after_this",
                      }),
                      color: "error",
                      variant: "outlined",
                      label: i18n.t("delete_after_this"),
                      method: "delete",
                    }
                  : null,
                {
                  href: routes.cashflow({
                    id: item?.id,
                    repeating: "only_this",
                  }),
                  color: "error",
                  variant: "contained",
                  label: i18n.t("delete"),
                  method: "delete",
                },
              ]}
            >
              <I18n this_action_cannot_be_undone />
            </Confirm>
            {item?.repetition && (
              <Confirm
                open={confirmUpdate}
                onClose={onCloseUpdate}
                title={i18n.t("update_repeating_items")}
                actions={[
                  {
                    label: i18n.t("update_this_only"),
                    onClick: () =>
                      (repeatingInputRef.current.value = "only_this"),
                    type: "submit",
                  },
                  {
                    label: i18n.t("update_all_after_this"),
                    variant: "contained",
                    onClick: () =>
                      (repeatingInputRef.current.value = "all_after_this"),
                    type: "submit",
                  },
                ]}
              ></Confirm>
            )}
            <input type="hidden" name="repeating" ref={repeatingInputRef} />
          </Box>
        </Form>
      )}
      {audits.length > 0 && (
        <Typography variant="h6" sx={{ fontSize: "75%", pl: 2 }}>
          <I18n audits />
        </Typography>
      )}
      {audits.length > 0 && (
        <Timeline
          sx={{
            [`& .${timelineOppositeContentClasses.root}`]: {
              flex: 0.2,
            },
          }}
        >
          {audits.map((audit, i) => (
            <TimelineItem key={i}>
              <TimelineOppositeContent color="textSecondary">
                <Typography variant="subtitle2" sx={{ fontSize: "75%" }}>
                  {i18n.toTime(
                    "time.formats.short",
                    Date.parse(audit.created_at),
                  )}
                </Typography>
                {audit.user && (
                  <Typography variant="subtitle2" sx={{ fontSize: "75%" }}>
                    {audit.user.name}
                  </Typography>
                )}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot />
                {audits.length - 1 > i && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="body2" sx={{ fontSize: "75%" }}>
                  {audit.action == "create" && !item.app_integration && (
                    <I18n audit_create />
                  )}
                  {audit.action == "create" && item.app_integration && (
                    <I18n
                      audit_create_with_app
                      app={item.app_integration.app.name}
                    />
                  )}
                  {audit.action == "update" &&
                    audit.changes.map((change, j) => (
                      <>
                        <I18n
                          key={j}
                          audit_update
                          name={change.key_name}
                          to={change.value}
                        />
                        {audit.changes.length - 1 > j && ", "}
                      </>
                    ))}
                  {audit.comment && <>&nbsp;({audit.comment})</>}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      )}
    </Stack>
  );
}

export default function (props) {
  const { open, onClose, item } = props;
  return (
    <RightDrawerLayout
      open={open}
      onClose={onClose}
      title={i18n.t("cash_flow_item")}
      icon={item ? PencilIcon : PlusCircleIcon}
    >
      <FormDrawerContent {...props} onClose={onClose} />
    </RightDrawerLayout>
  );
}
