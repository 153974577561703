import React, { useState } from "react";
import { RootApplicationLayout } from "../../layouts/RootApplicationLayout";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CheckBox from "@mui/material/Checkbox";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import MuiPhoneNumber from "material-ui-phone-number-2";
import I18n from "../../common/I18n";
import i18n from "../../../utils/i18n";
import { Form } from "../../common/Form";
import SubmitButton from "../../common/SubmitButton";
import routes from "../../../utils/routes";

const PHONE_REGX =
  /^$|^((\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const SCHEMA = yup.object({
  company_name: yup
    .string()
    .required(
      i18n.t("field_cannot_be_blank", { field: i18n.t("company_name") }),
    ),
  name: yup
    .string()
    .required(i18n.t("field_cannot_be_blank", { field: i18n.t("name") })),
  email: yup
    .string()
    .required(
      i18n.t("field_cannot_be_blank", { field: i18n.t("email_address") }),
    )
    .email(i18n.t("field_is_incorrect", { field: i18n.t("email_address") })),
  phone_number: yup
    .string()
    .matches(
      PHONE_REGX,
      i18n.t("field_is_incorrect", { field: i18n.t("phone_number") }),
    ),
  password: yup
    .string()
    .required(i18n.t("password_must_be_at_least_12_characters"))
    .min(12, i18n.t("password_must_be_at_least_12_characters"))
    .matches(
      /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])/,
      i18n.t("password_must_be_at_least_12_characters"),
    ),
  password_confirmation: yup
    .string()
    .oneOf(
      [yup.ref("password"), null],
      i18n.t("you_have_to_type_same_password"),
    ),
});

export default function ({ flash }) {
  const [acknowledged, setAcknowledged] = useState(false);
  const toggleAcknowledged = () => setAcknowledged(!acknowledged);
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      company_name: "",
      name: "",
      email: "",
      phone_number: "",
      password: "",
      password_confirmation: "",
    },
    resolver: yupResolver(SCHEMA),
  });
  const phone_number = watch("phone_number");

  return (
    <RootApplicationLayout title="welcome" flash={flash}>
      <Form
        action={routes.user_registration()}
        method="post"
        onSubmit={(event) =>
          handleSubmit(
            () => undefined,
            () => event.preventDefault(),
          )()
        }
      >
        <Stack spacing={2}>
          <Typography variant="h3">
            <I18n sign_up />
          </Typography>
          <TextField
            fullWidth
            required
            variant="standard"
            label={i18n.t("user_name")}
            helperText={errors.name?.message}
            error={"name" in errors}
            {...register("name")}
            autoFocus={true}
            autoComplete="email"
          />
          <TextField
            fullWidth
            required
            variant="standard"
            label={i18n.t("email_address")}
            helperText={errors.email?.message}
            error={"email" in errors}
            {...register("email")}
            autoComplete="name"
          />
          <TextField
            fullWidth
            required
            variant="standard"
            label={i18n.t("company_name")}
            helperText={errors.name?.message}
            error={"name" in errors}
            {...register("company_name")}
            autoFocus={true}
          />
          <MuiPhoneNumber
            defaultCountry={"sg"}
            required
            fullWidth
            variant="standard"
            label={i18n.t("phone_number")}
            helperText={errors.phone_number?.message}
            name="phone_number"
            value={phone_number}
            onChange={(value) => setValue("phone_number", value)}
            error={"phone_number" in errors}
          />
          <TextField
            fullWidth
            required
            type="password"
            variant="standard"
            label={i18n.t("password")}
            helperText={errors.password?.message}
            error={"password" in errors}
            {...register("password")}
          />
          <TextField
            fullWidth
            required
            type="password"
            variant="standard"
            label={i18n.t("password_confirm")}
            helperText={errors.password_confirmation?.message}
            error={"password_confirmation" in errors}
            {...register("password_confirmation")}
          />
          <SubmitButton
            type="submit"
            variant="contained"
            size="large"
            disabled={!acknowledged}
          >
            <I18n sign_up />
          </SubmitButton>
          <FormGroup>
            <FormControlLabel
              sx={{ alignItems: "start" }}
              control={
                <CheckBox
                  {...register("acknowledge_agreements")}
                  onChange={toggleAcknowledged}
                  checked={acknowledged}
                />
              }
              label={
                <Typography variant="body2" sx={{ fontSize: "80%" }}>
                  {i18n.t("acknowledge_agreements_disclaimer")}
                </Typography>
              }
            />
            <Typography variant="body2" sx={{ fontSize: "75%" }}>
              <ul>
                <li>
                  <a
                    target="_blank"
                    href={i18n.t("platform_service_agreement_url")}
                    rel="noreferrer"
                  >
                    <I18n platform_service_agreement />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href={i18n.t("data_access_and_data_usage_agreement_url")}
                    rel="noreferrer"
                  >
                    <I18n data_access_and_data_usage_agreement />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href={i18n.t("limited_power_of_attorney_url")}
                    rel="noreferrer"
                  >
                    <I18n limited_power_of_attorney />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href={i18n.t("privacy_policy_url")}
                    rel="noreferrer"
                  >
                    <I18n privacy_policy />
                  </a>
                </li>
              </ul>
            </Typography>
          </FormGroup>
          <Button
            href={routes.new_user_session()}
            fullWidth
            sx={{ marginY: 1 }}
          >
            <I18n sign_in />
          </Button>
        </Stack>
      </Form>
    </RootApplicationLayout>
  );
}
