import React from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { Form } from "../../common/Form";
import { PopupApplicationLayout } from "../../layouts/PopupApplicationLayout";
import SubmitButton from "../../common/SubmitButton";
import Dropdown from "../../common/Dropdown";
import BankCard from "./BankCard";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import I18n from "../../common/I18n";
import i18n from "../../../utils/i18n";
import routes from "../../../utils/routes";

const CURRENCIES = i18n.t("currencies");
const SCHEMA = yup.object({
  account_number: yup
    .string()
    .required(
      i18n.t("field_cannot_be_blank", { field: i18n.t("bank_account_number") }),
    ),
  account_name: yup
    .string()
    .required(
      i18n.t("field_cannot_be_blank", { field: i18n.t("bank_account_name") }),
    ),
  currency: yup
    .string()
    .required(i18n.t("field_cannot_be_blank", { field: i18n.t("currency") })),
});

export default function ({ bank }) {
  const currencies = CURRENCIES.filter((currency) =>
    bank.profile.supported_currencies.includes(currency.value),
  );
  const handleAccountIntegrationCancel = () => {
    window.opener.handleAccountIntegrationCancel();
    window.close();
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      account_number: "",
      account_name: "",
      currency: currencies[0].value,
    },
    resolver: yupResolver(SCHEMA),
  });

  return (
    <PopupApplicationLayout title="account_integration">
      <Form
        action={routes.initiate_account_integrations()}
        method="get"
        onSubmit={(event) =>
          handleSubmit(
            () => undefined,
            () => event.preventDefault(),
          )()
        }
      >
        <Stack spacing={2}>
          <BankCard bank={bank} />
          <Card>
            <CardHeader title={i18n.t("account_info")} />
            <Divider />
            <CardContent>
              <Stack spacing={2}>
                <input type="hidden" name="bank_id" value={bank.id} />
                <TextField
                  fullWidth
                  required
                  variant="standard"
                  label={i18n.t("bank_account_number")}
                  {...register("account_number")}
                  error={"account_number" in errors}
                  autoFocus
                  autoComplete="off"
                />
                <Dropdown
                  fullWidth
                  variant="standard"
                  label={i18n.t("currency")}
                  values={currencies}
                  defaultValue={currencies[0].value}
                  {...register("currency")}
                  helperText={errors.currency?.message}
                  error={"currency" in errors}
                />
                <TextField
                  fullWidth
                  required
                  variant="standard"
                  label={i18n.t("bank_account_name")}
                  {...register("account_name")}
                  error={"account_name" in errors}
                  autoComplete="off"
                />
              </Stack>
            </CardContent>
          </Card>
          <div />
          <SubmitButton variant="contained" type="submit">
            <I18n submit />
          </SubmitButton>
          <Button
            onClick={handleAccountIntegrationCancel}
            sx={{ marginLeft: "auto!important", marginTop: "6px!important" }}
          >
            <I18n close />
          </Button>
        </Stack>
      </Form>
    </PopupApplicationLayout>
  );
}
