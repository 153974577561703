import React, { useState, useRef, useEffect } from "react";
import LoadingButton from "@mui/lab/LoadingButton";

export default function ({
  children,
  onClick,
  formRef,
  disabled,
  resetRef,
  forceLoading,
  ...props
}) {
  const buttonRef = useRef();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (props.type == "submit") {
      const form = formRef?.current || buttonRef.current.closest("form");
      form.addEventListener("turbo:submit-start", () => setLoading(true));
    } else if (resetRef) resetRef.current = () => setLoading(false);
  }, []);
  useEffect(() => {
    if (forceLoading) setLoading(true);
  }, [forceLoading]);
  const onLoadingClick = (e) => {
    if (onClick) onClick(e);
    setLoading(true);
  };

  return (
    <LoadingButton
      disabled={disabled}
      loading={loading}
      ref={buttonRef}
      onClick={(props.type != "submit" && onLoadingClick) || onClick}
      {...props}
    >
      {children}
    </LoadingButton>
  );
}
