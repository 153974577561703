import React from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { Form } from "../../common/Form";
import { PopupApplicationLayout } from "../../layouts/PopupApplicationLayout";
import SubmitButton from "../../common/SubmitButton";
import BankCard from "./BankCard";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import I18n from "../../common/I18n";
import i18n from "../../../utils/i18n";
import routes from "../../../utils/routes";

const SCHEMA = yup.object({
  api_key: yup
    .string()
    .required(i18n.t("field_cannot_be_blank", { field: "API Key" })),
});

export default function ({ bank, identifier }) {
  const handleAccountIntegrationCancel = () => {
    window.opener.handleAccountIntegrationCancel();
    window.close();
  };
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      api_key: "",
    },
    resolver: yupResolver(SCHEMA),
  });
  const api_key = watch("api_key");

  return (
    <PopupApplicationLayout title="account_integration">
      <Form
        action={routes.initiate_account_integrations()}
        method="get"
        onSubmit={(event) =>
          handleSubmit(
            () => undefined,
            () => event.preventDefault(),
          )()
        }
      >
        {identifier && (
          <input type="hidden" name="identifier" value={identifier} />
        )}
        <Stack spacing={2}>
          <BankCard bank={bank} />
          <Card>
            <CardHeader title={i18n.t("api_key")} />
            <Divider />
            <CardContent>
              <input type="hidden" name="bank_id" value={bank.id} />
              <TextField
                fullWidth
                required
                variant="standard"
                label={i18n.t("api_key")}
                {...register("api_key")}
                error={"api_key" in errors}
                autoFocus
                autoComplete="off"
              />
            </CardContent>
          </Card>
          <div />
          <SubmitButton disabled={!api_key} variant="contained" type="submit">
            <I18n submit />
          </SubmitButton>
          <Button
            onClick={handleAccountIntegrationCancel}
            sx={{ marginLeft: "auto!important", marginTop: "6px!important" }}
          >
            <I18n close />
          </Button>
        </Stack>
      </Form>
    </PopupApplicationLayout>
  );
}
