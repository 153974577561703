import React from "react";
import Alert from "@mui/material/Alert";

const styles = {
  Alert: {
    marginY: 1,
  },
};

const MAPING = { alert: "error", notice: "success" };
export default function ({ flash }) {
  return (
    <>
      {flash &&
        flash
          .filter((msg) => msg[1] && MAPING[msg[0]])
          .map((msg, i) => (
            <Alert key={i} severity={MAPING[msg[0]]} sx={styles.Alert}>
              {msg[1]}
            </Alert>
          ))}
    </>
  );
}
