import React from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { PopupApplicationLayout } from "../layouts/PopupApplicationLayout";
import I18n from "../common/I18n";

export default function ({ popup, flash }) {
  window.onbeforeunload = function () {
    if (window.opener?.postMessage)
      window.opener.postMessage({ type: "login_success" }, popup);
  };

  return (
    <PopupApplicationLayout title="login_success" flash={flash}>
      <Stack spacing={2}>
        <div />
        <Button fullWidth variant="contained" onClick={window.close}>
          <I18n close />
        </Button>
      </Stack>
    </PopupApplicationLayout>
  );
}
